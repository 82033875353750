import React from "react"
import Nav from "./nav"
import cx from "classnames"

const Header = ({ title, navLinkClass, children, ...props }) => {
  const className = cx(
    "pb-24",
    props.className
  )

  return(
    <div className={className}>
      <div className="container">
        <Nav linkClass={navLinkClass} />
        { children ? children : (
          <h1 className="mt-32 md:mt-48">{title}</h1>
        )}
      </div>
    </div>
  )
}

export default Header
