import React from "react"
import { Link } from 'gatsby'
import cx from "classnames"
import avatar from '../images/avatar.png'

const Nav = ({ linkClass }) => {

  const linkClassName = cx(
    "px-2.5 py-1.5 rounded no-underline transition ease-in-out duration-200",
    linkClass || "hover:bg-black/5"
  )

  return(
    <div className="flex items-center justify-between py-10">
      <Link to="/"><img src={avatar} className="w-8 h-8 md:w-10 md:h-10 rounded grayscale hover:grayscale-0 transition ease-in-out duration-200" /></Link>
      <nav>
        <Link to="/projects" className={linkClassName + " mr-1 md:mr-2"}>Projects</Link>
        <Link to="/bookmarks" className={linkClassName + " mr-1 md:mr-2"}>Bookmarks</Link>
        <Link to="/social" className={linkClassName}>Social</Link>
      </nav>
    </div>
  )
}

export default Nav
