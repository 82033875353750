import React from "react"
import Nav from "./nav"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return(
    <div className="py-20 text-2xs text-gray-600 cursor-default">
      <div className="container flex justify-between md:items-center flex-col md:flex-row">
        <p className="mb-2 md:mb-0">Made with ☕️ from Sarasota, FL</p>
        <p className="text-gray-300 md:text-gray-600">© {currentYear}</p>
      </div>
    </div>
  )
}

export default Footer
