import React from "react"
import { Helmet } from 'react-helmet'
import favicon from "../images/favicon.ico"
import socialShare from "../images/twitter.png"

const Wrapper = ({ title, description, path, children, ...props }) => {
  const domain = "https://dylanfeltus.com"
  const defaultTitle = "Dylan Feltus – Designer and Indie Maker"
  const defaultDescription = "Hey, I'm Dylan. I'm a designer, developer, and indie maker. Follow along as I build in public."
  const socialImagePath = props.shareImage || socialShare

  return(
    <main className="relative overflow-hidden">
      <Helmet>
        <title>{title || defaultTitle}</title>
        <meta name="description" content={description || defaultDescription} />
        <link rel="icon" type="image/png" href={domain + favicon} />
        <meta name="og:title" content={title || defaultTitle} />
        <meta name="og:description" content={description || defaultDescription} />
        <meta name="og:image" content={domain + socialImagePath} />
        <meta name="og:url" content={domain + path} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {children}
    </main>
  )
}

export default Wrapper
